<template>
  <div class="app-container">
    <div class="head-container">
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="起始日期" end-placeholder="截止日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <quick-select url="api/warehouse" v-model="query.warehouseIds" multiple filterable collapse-tags placeholder="仓库" class="filter-item" @change="toQuery" clearable style="width: 240px;" />
      <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 160px;" />
      <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 160px;" />
      <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 160px;" />
      <el-input v-model="query.goodsName" :maxlength="20" clearable placeholder="输入商品名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.code" :maxlength="20" clearable placeholder="输入商品编码搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="success" @click="toDownload" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="code" label="商品编码" min-width="130" fixed />
      <el-table-column prop="erpCode" label="ERP编码" min-width="130" fixed />
      <el-table-column prop="brandName" label="品牌" width="120px" />
      <el-table-column prop="seriesName" label="系列" width="120px" />
      <el-table-column prop="categoryName" label="分类" width="120px" />
      <el-table-column prop="goodsName" label="商品名称" min-width="240px" />
      <el-table-column prop="warehouseName" label="所在仓库" width="120px" />
      <el-table-column prop="inoutCount" label="损益数量" width="120px">
        <template slot-scope="scope">
          <span :style="{ 'color': scope.row.inoutCount>0 ? '#F56C6C': scope.row.inoutCount==0?'#000000':'#67C23A' }">{{scope.row.inoutCount}}</span>
        </template>
      </el-table-column>
      <el-table-column width="80px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">详情</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <detail-dialog ref="detail" :times="query.dateRange" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import detailDialog from "./form";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  mixins: [initData],
  components: { detailDialog },
  data() {
    return {
      downloadLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        dateRange: [
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          new Date().getTime(),
        ],
        warehouseIds: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        goodsName: null,
        code: null,
      },
    };
  },

  created() {
    this.init();
  },
  methods: {
    checkPermission,
    makeParams() {
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begDate = query.dateRange[0];
        query.endDate = query.dateRange[1];
      }
      delete query.dateRange;
      return query;
    },
    beforeInit() {
      this.url = "api/stocktaking/sumByGoods";
      let query = this.makeParams();
      this.params = Object.assign({ sort: "createAt,desc" }, query);
      return true;
    },

    detail(row) {
      this.$refs.detail && this.$refs.detail.restForm(row);
    },
    toDownload() {
      let params = this.makeParams();
      this.downloadLoading = true;
      download("api/stocktaking/sumByGoods/download", params)
        .then((result) => {
          downloadFile(result, "商品盘点信息", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>